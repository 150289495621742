function App() {
  return (
    <div className="App px-5">
      <h1 className="mt-8 font-semibold text-5xl text-center leading-tight">NIT DGP <br />  Student Team</h1>
      <img src={require('./pages/img/nitdgp.png')} className="block animate-bounce mx-auto mt-20 h-40" alt="Nit durgpaur" />
      <marquee className="py-5" behavior="" direction="">
        <h1 className="text-4xl mt-10 font-semibold">Website Coming Live Soon</h1>
      </marquee>
    </div>
  );
}

export default App;
